<template>
  <main class="mr-4 :md:mr-0 mb-4">
    <trac-loading v-if="isLoading" />
    <div class="" v-if="stores">
      <div class="flex justify-between items-center">
        <ul class="flex-col mt-12 mb-8">
          <li>Stores</li>
          <li class="font-bold mt-4 text-xl">All Stores</li>
        </ul>
        <div class="">
          <trac-button @button-clicked="$router.push({ name: 'AddStore' })"
            >Add Store</trac-button
          >
        </div>
      </div>

      <div class="big-shadow">
        <table class="w-full table-auto">
          <thead class="bg-blue-100">
            <tr>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                Store name
              </th>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                State
              </th>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                Phone
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="gotoEditStore(store)"
              v-for="(store, i) in stores"
              :key="i"
              :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
              class="hover:bg-gray-200 cursor-pointer"
            >
              <td class="p-4 text-xs font-bold capitalize">{{ store.name }}</td>
              <td class="p-4 text-xs capitalize">{{ store.state }}</td>
              <td class="p-4 text-xs">{{ store.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";

export default {
  data() {
    return {
      isLoading: false,
      stores: null,
    };
  },
  async created() {
    DELETE_LOCAL_DB_DATA("store-edit-details");
    this.isLoading = true;
    await this.fetchAllStores();
    this.isLoading = false;
  },
  methods: {
    gotoEditStore(store) {
      SAVE_LOCAL_DB_DATA("store-edit-details", store);
      this.$router.push({ name: "AddStore" });
    },
    async fetchAllStores() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
